

























































import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatcherService, UserNoteService } from "@/core/services";
import { TrackingNoteModel } from "../models/TrackingNoteModel";
import _ from "lodash";
import * as moment from "moment";

@Component({})
export default class OverdueRecallDatesCpt extends AppVue {
  model: TrackingNoteModel[] = [];
  loading = false;

  async completeRecallDate(note: TrackingNoteModel) {
    try {
      note.recallDateCompleted = moment.utc();
      await UserNoteService.updateNote(note);
      this.initializeModel();
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Recall date completed successfully`,
      });
    } catch {}
  }

  async created() {
    this.initializeModel();
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await WatcherService.overdueRecallDates();
    } finally {
      this.loading = false;
    }
  }
}
