































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { TagModel, TagService, WatcherService, TrackingPagedCollectionFilter } from "@/core/services";
import { SectorIconCpt, BlankSlateCpt, WatchedButton } from "@/core/components";
import { PagedCollection, UserWatch, UserWatchesResult } from "@/core/models";
import TrackingNotesCpt from "@/modules/tracking/components/TrackingNotesCpt.vue";
import { TrackingQuerystringFactory } from "@/modules/tracking/factories/trackingQueryStringFactory";
import moment from "moment";
import {
  ExportFormatModel,
  ExportService,
  TrackingExportConfigurationModel,
} from "@/modules/export/services/export.service";
import { ExportFilterCpt } from ".";
import { CreateExportCpt } from "@/modules/export/components";
import { ProjectTagListCpt } from "@/modules/tags";

@Component({
  components: {
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    SectorIconCpt: () => Promise.resolve(SectorIconCpt),
    BlankSlateCpt: () => Promise.resolve(BlankSlateCpt),
    WatchedButton: () => Promise.resolve(WatchedButton),
    ExportFilterCpt: () => Promise.resolve(ExportFilterCpt),
    CreateExportCpt: () => Promise.resolve(CreateExportCpt),
    ProjectTagListCpt: () => Promise.resolve(ProjectTagListCpt),
  },
})
export default class WatchedProjectListCpt extends AppVue {
  @Prop({ default: false }) viewAll: boolean;
  @Prop({ default: false }) compact: boolean;
  loading = false;
  results: PagedCollection<UserWatch> = new PagedCollection<UserWatch>();
  model: UserWatchesResult = new UserWatchesResult();
  selectedProjectId: number = 0; // for loading notes
  selectedProject: UserWatch;
  selectedProjectTitle: string = "";
  availableUsers = [{ userId: -99, name: "", lastname: "" }];
  selectedUser: any = {};
  allTags: TagModel[] = [];
  selectedTags: number[] = [];
  filterUntracked = false;
  showExportDialog = false;
  filter: TrackingPagedCollectionFilter = {
    page: 1,
    pageSize: 10,
    filterUntracked: false,
    selectedTags: [],
    selectedUser: this.currentUserId,
  };
  get exportFilters() {
    return {
      tags: this.selectedTags,
      userId: this.selectedUser.userId || 0,
      untrackedOnly: this.filterUntracked,
      outputSettings: new ExportFormatModel(),
    } as TrackingExportConfigurationModel;
  }

  get itemsToShow() {
    if (!this.results.items) {
      return [];
    }
    if (!this.viewAll) {
      return this.results.items!.slice(0, 5);
    }
    return this.results.items;
  }

  get showNotesDialog() {
    return this.selectedProjectId > 0;
  }

  changePage() {
    this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  nextPage() {
    this.filter.page++;
    this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  prevPage() {
    if (this.filter.page > 1) {
      this.filter.page--;
      this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
    }
  }

  async applyHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.filter.page = 1;
    await this.filterHandler(selectedUser, selectedTags, filterUntracked);
  }

  async updateHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.filter.page = 1;
    await this.filterHandler(selectedUser, selectedTags, filterUntracked);
  }

  async selectUser(user: any) {
    this.filter.page = 1;

    await this.filterHandler(user, this.selectedTags, this.filterUntracked);
  }

  async filterHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.loading = true;
    this.selectedUser = selectedUser;
    this.filterUntracked = filterUntracked;
    this.selectedTags = selectedTags;
    try {
      this.results = await ExportService.filterTrackedProjects(
        selectedUser.userId,
        selectedTags,
        this.filter.page,
        filterUntracked,
      );
      if (this.viewAll) {
        this.$router
          .replace({
            path: this.$route.params[0],
            query: TrackingQuerystringFactory.default(
              this.filter,
              this.selectedTags,
              this.filterUntracked,
              this.selectedUser,
            ),
          })
          // the catch is required because vue doesn't allow reloading the same url.
          // By design. Yes, I think it's wrong, and I am not the only one.
          .catch((err) => {});
      }
      this.model.watches = this.results.items;
      this.model.total = this.results.recordCount;
    } catch {}
    this.loading = false;
  }

  exportHandler(a: any, b: number[], c: boolean) {
    this.selectedUser = a;
    this.selectedTags = b;
    this.filterUntracked = c;
    this.showExportDialog = true;
  }

  isUpdated(item: UserWatch) {
    return moment().diff(item.project.dateUpdated, "days") < 7;
  }

  filterByUser(userId: number) {
    this.selectedUser = this.availableUsers.find((x) => x.userId === userId) || this.selectedUser;
  }

  async deleteHandler() {
    await WatcherService.unwatchAllFilteredProjects(this.selectedUser.userId, this.selectedTags);
    await this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  async removeWatch(item: UserWatch) {
    WatcherService.unwatchProject(item.projectID);
    // reload the current filter without any change
    await this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  addNote(item: UserWatch) {
    this.selectedProjectId = item.projectID;
    this.selectedProject = item;
    this.selectedProjectTitle = item.project.title;
  }

  handleNotesClose() {
    this.selectedProjectId = 0;
    this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  get counter() {
    if (this.results && this.results.items) {
      return this.results.items.length;
    }
    return 0;
  }

  convertQuerystring() {
    this.filter = TrackingQuerystringFactory.convertFromQuerystring(this.$route.query, this.filter);
  }

  async mounted() {
    this.initializeModel();
  }

  async created() {
    this.convertQuerystring();
  }

  async initializeModel() {
    this.loading = true;

    try {
      await this.filterHandler(
        { userId: this.filter.selectedUser },
        this.filter.selectedTags,
        this.filter.filterUntracked,
      );
    } finally {
      this.loading = false;
    }
  }
}
