























import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import {
  WatchedProjectListCpt,
  WatchedCompanyListCpt,
  RecallDatesCpt,
  OverdueRecallDatesCpt,
} from "@/modules/tracking/components";

@Component({
  components: {
    WatchedCompanyListCpt: () => Promise.resolve(WatchedCompanyListCpt),
    WatchedProjectListCpt: () => Promise.resolve(WatchedProjectListCpt),
    RecallDatesCpt: () => Promise.resolve(RecallDatesCpt),
    OverdueRecallDatesCpt: () => Promise.resolve(OverdueRecallDatesCpt),
  },
})
export default class TrackingView extends AppVue {
  activeTab = "projects";
  get showProject() {
    return this.showProjectsDialog || (!this.showCompaniesDialog && !this.showProjectsDialog);
  }
  get showCompany() {
    return this.showCompaniesDialog || (!this.showCompaniesDialog && !this.showProjectsDialog);
  }
  mounted() {
    super.updateTitle("Tracking");
    this.activeTab = this.showProjectsDialog ? "projects" : this.showCompaniesDialog ? "companies" : "all";
  }

  get showProjectsDialog() {
    return this.$route.path === "/tracking/projects";
  }

  get showCompaniesDialog() {
    return this.$route.path === "/tracking/companies";
  }
  tabClick() {
    if (this.activeTab === "projects") {
      if (!this.showProjectsDialog) {
        this.$router.push("/tracking/projects");
      }
    }
    if (this.activeTab === "companies") {
      if (!this.showCompaniesDialog) {
        this.$router.push("/tracking/companies");
      }
    }
  }
}
