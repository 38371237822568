import { ProjectModel, CompanyDetailModel } from "@/core/models";
import { Moment } from "moment";

export class TrackingNoteModel {
    note: string;
    userWatchNoteId: number;
    recallDate?: Moment;
    recallDateCompleted?: Moment;
    companyId?: number;
    projectId?: number;
    project?: ProjectModel;
    company?: CompanyDetailModel;
    userName: string;
    fullName: string;
    dateCreated: string;
    isEdit: boolean = false;
}