
















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatchedProjectListCpt } from "@/modules/tracking/components";

@Component({
  components: {
    WatchedProjectListCpt: () => Promise.resolve(WatchedProjectListCpt),
  },
})
export default class TrackingProjectsView extends AppVue {
  title = "Filter and export tracked projects";

  backToTracking() {
    this.$router.push({
      name: "tracking",
    });
  }

  mounted() {
    super.updateTitle(this.title);
  }
}
