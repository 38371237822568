


















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatcherService, CalendarService } from "@/core/services";
import { TrackingNoteModel } from "../models/TrackingNoteModel";
import _ from "lodash";

@Component({})
export default class RecallDatesCpt extends AppVue {
  model: TrackingNoteModel[] = [];
  overdue: TrackingNoteModel[] = [];
  loading = false;
  calendarUrl = "";
  showIcalHelp = false;

  get counter() {
    if (this.model) {
      return this.model && this.model.length;
    }
    return 0;
  }

  copy() {
    (this.$refs.icalLink as HTMLElement)?.focus();
    document.execCommand("copy");
  }

  get showRecallCalendar() {
    return this.isRecallCalendarVisible == "true";
  }

  get url() {
    return process.env.VUE_APP_WEBAPI_URL + this.calendarUrl;
  }

  get groupedDates() {
    return _.groupBy(this.model, this.getDate);
  }

  async created() {
    this.initializeModel();
  }

  private getDate(item: TrackingNoteModel) {
    return item.recallDate;
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await WatcherService.upcomingRecallDates();
      this.overdue = await WatcherService.overdueRecallDates();
      this.calendarUrl = await CalendarService.getCalendarUrl();
    } finally {
      this.loading = false;
    }
  }
}
