



























































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { TrackingNoteModel } from "../models/TrackingNoteModel";
import { WatcherService, UserNoteService, UserTrackerModel, TagModel, TagService } from "@/core/services";
import moment from "moment";
import AppVue from "@/AppVue.vue";
import { BlankSlateCpt } from "@/core/components";
import { propsBinder } from "vue2-leaflet";

export class NameList {
  results: TrackingNoteModel[];
  totalRecords: number;
}

@Component({
  components: {
    BlankSlateCpt: () => Promise.resolve(BlankSlateCpt),
  },
})
export default class TrackingNotesCpt extends AppVue {
  @Prop({ default: 0 }) projectId: number;
  @Prop({ default: 0 }) companyId: number;
  @Prop({ default: false }) status: any;
  @Prop({ default: false }) onlyUsers: boolean;

  loading: boolean = false;
  trackers: UserTrackerModel[] = [];
  newNote: TrackingNoteModel = new TrackingNoteModel();
  dateFormat: string = "";
  currentlyEdited: TrackingNoteModel = new TrackingNoteModel();
  model: NameList = { results: [], totalRecords: 0 };
  addNote = false;
  availableUsers = [{ userId: -99, name: "", lastname: "" }];
  get trackersIdList() {
    return this.trackers.map((y) => y.userId);
  }
  get availableUsersNotTracking() {
    return this.availableUsers.filter((x) => this.trackersIdList.indexOf(x.userId) === -1);
  }
  @Watch("projectId")
  async reloadNotesOnProjectChange() {
    await this.init();
  }

  @Watch("companyId")
  async reloadNotesOnCompanyChange() {
    await this.init();
  }
  get optionPicker() {
    return {
      disabledDate: (date: Date) => date <= new Date(), // only future dates
    };
  }
  get notePlaceholder() {
    if (this.companyId) {
      return `Notes/findings about this company`;
    }

    return `Notes/findings about this project`;
  }
  get isFormEnabled() {
    if (!this.trackers) {
      return false;
    }
    if (!!this.trackers && this.trackers.length === 0) {
      return false;
    }
    if (this.trackers.find((x) => x.userId === this.currentUserId) === null) {
      return false;
    }
    return true;
  }

  async removeUser(userId: number) {
    this.loading = true;

    const u = this.availableUsers.find((x) => x.userId === userId);

    if (this.currentUserId !== userId) {
      this.newNote.note = `Removed ${u?.name} ${u?.lastname} from trackers`;
    }

    if (this.projectId) {
      WatcherService.unwatchProjectForUser(this.projectId, userId);
      if (this.currentUserId !== userId) {
        this.addNoteToEntity();
      }
    } else if (this.companyId) {
      WatcherService.unwatchCompanyForUser(this.companyId, userId);
      if (this.currentUserId !== userId) {
        this.addNoteToEntity();
      }
    }
    await this.reload(0, 0);
  }

  async addUser(userId: number) {
    this.loading = true;
    const u = this.availableUsers.find((x) => x.userId === userId);
    if (!!u) {
      // fake it to show something to the user
      this.trackers.push({ userId: u?.userId, fullname: `${u?.name} ${u?.lastname}` });
      if (this.currentUserId !== userId) {
        this.newNote.note = `Added ${u?.name} ${u?.lastname} to trackers`;
      }
    }
    if (this.projectId) {
      WatcherService.watchProjectForUser(this.projectId, userId);
      if (this.currentUserId !== userId) {
        this.addNoteToEntity();
      }
    } else if (this.companyId) {
      WatcherService.watchCompanyForUser(this.companyId, userId);
      if (this.currentUserId !== userId) {
        this.addNoteToEntity();
      }
    }
    await this.reload(0, 0);
    this.$emit("trackingUpdated");
  }

  handleCommand(command: any) {
    if (command.name === "edit") {
      this.editNote(command.data);
    }
    if (command.name === "delete") {
      this.deleteNote(command.data);
    }
    if (command.name === "deleteRecall") {
      this.deleteRecallDate(command.data);
    }
    if (command.name === "completeRecall") {
      this.completeRecallDate(command.data);
    }
  }

  @Watch("status")
  async reload(newValue: any, oldValue: any) {
    await this.init();
  }

  async deleteNote(note: TrackingNoteModel) {
    try {
      await UserNoteService.deleteNote(note.userWatchNoteId);
      const index = this.model.results.findIndex((x) => x.userWatchNoteId === note.userWatchNoteId);
      this.model.results.splice(index, 1);
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Note deleted successfully`,
      });
    } catch {}
  }

  async editNote(note: TrackingNoteModel) {
    this.currentlyEdited = { ...note };
  }
  async cancelEditOperation() {
    this.currentlyEdited = new TrackingNoteModel();
  }
  async deleteRecallDate(note: TrackingNoteModel) {
    try {
      note.recallDate = undefined;
      const res = await UserNoteService.updateNote(note);
      const index = this.model.results.findIndex((x) => x.userWatchNoteId === this.currentlyEdited.userWatchNoteId);
      this.model.results[index] = note;
      this.currentlyEdited = new TrackingNoteModel();
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Recall date removed updated successfully`,
      });
    } catch {}
  }
  async completeRecallDate(note: TrackingNoteModel) {
    try {
      note.recallDateCompleted = moment.utc();
      await UserNoteService.updateNote(note);
      const index = this.model.results.findIndex((x) => x.userWatchNoteId === this.currentlyEdited.userWatchNoteId);
      this.model.results[index] = note;
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Recall date completed successfully`,
      });
    } catch {}
  }
  async updateNote(note: TrackingNoteModel) {
    try {
      const res = await UserNoteService.updateNote(note);
      const index = this.model.results.findIndex((x) => x.userWatchNoteId === this.currentlyEdited.userWatchNoteId);
      this.model.results[index] = note;
      this.currentlyEdited = new TrackingNoteModel();
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Note updated successfully`,
      });
    } catch {}
  }

  async addNoteToEntity() {
    try {
      this.newNote.companyId = this.companyId;
      this.newNote.projectId = this.projectId;
      const note = await UserNoteService.addNote(this.newNote);
      this.model.results.unshift(note);
      this.$notify({
        title: "Success!",
        type: "success",
        message: `Note added successfully`,
      });
      this.newNote = new TrackingNoteModel();
      this.addNote = false;
    } catch {}
  }

  async created() {
    await this.init();
  }
  async init() {
    this.loading = true;
    this.dateFormat = "yyyy-MM-dd";
    this.model.results = await UserNoteService.getNotes(this.projectId, this.companyId, 0);
    this.trackers = await WatcherService.getAllUsersTracking(this.projectId, this.companyId);
    this.availableUsers = await WatcherService.getAllVisibleUsers();
    this.loading = false;
  }
  async mounted() {}
}
