var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dashboard-container recall-dates",attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading"}},[_vm._m(0),_c('div',{staticClass:"overdue"},_vm._l((_vm.model),function(item){return _c('div',{key:item.userWatchNoteId,staticClass:"overdue-item"},[_c('div',[(item.projectId)?_c('router-link',{attrs:{"to":{
              name: 'Project',
              params: {
              projectId: item.project.projectId,
              },
          }}},[_vm._v(" "+_vm._s(item.project.title)+" ")]):_vm._e(),(item.companyId)?_c('router-link',{attrs:{"to":{
        name: 'CompanyDetail',
        params: {
          companyId: item.company.companyID,
        },
      }}},[_vm._v(_vm._s(item.company.name))]):_vm._e()],1),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("cisDateExtended")(item.recallDate)))]),_c('div',{staticClass:"actions"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Mark recall date completed","placement":"top"}},[_c('el-popconfirm',{attrs:{"title":"Are you sure you want to mark this recall date as completed?","confirm-button-text":"Complete","hide-icon":true,"confirm-button-type":"success","cancel-button-text":"Cancel"},on:{"confirm":function($event){return _vm.completeRecallDate(item)}}},[_c('el-button',{attrs:{"slot":"reference","icon":"el-icon-check","type":"success","plain":"","size":"mini","title":"Mark recall date completed","round":""},slot:"reference"})],1)],1)],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h2',[_vm._v("Overdue recall dates")])])}]

export { render, staticRenderFns }