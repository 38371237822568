
















import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatchedCompanyListCpt } from "@/modules/tracking/components";

@Component({
  components: {
    WatchedCompanyListCpt: () => Promise.resolve(WatchedCompanyListCpt),
  },
})
export default class TrackingCompaniesView extends AppVue {
  title = "Filter and export tracked companies";

  backToTracking() {
    this.$router.push({
      name: "tracking",
    });
  }

  mounted() {
    super.updateTitle(this.title);
  }
}
