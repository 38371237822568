var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model)?_c('div',_vm._l((_vm.model),function(activity){return _c('div',{key:activity.activityDate,staticClass:"activity"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"person"},[_vm._v(" "+_vm._s(activity.name)+" ")]),_c('div',{staticClass:"date"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("cisDate")(activity.activityDate))+" ")])])]),_c('div',{staticClass:"description"},[(activity.note)?_c('p',[_c('strong',[_vm._v("Note")]),_vm._v(" "+_vm._s(activity.note)+" ")]):_vm._e(),(activity.recallDate)?_c('p',[_c('strong',[_vm._v("Recall date")]),_vm._v(" "+_vm._s(_vm._f("cisDate")(activity.recallDate))+" ")]):_vm._e(),(activity.project)?_c('p',[(activity.isRemove && !(activity.note || activity.recallDate))?_c('span',[_vm._v("No longer tracking project")]):_vm._e(),(!activity.isRemove && !(activity.note || activity.recallDate))?_c('span',[_vm._v("Started tracking project")]):_vm._e(),_c('router-link',{attrs:{"to":{
      name: 'Project',
      params: {
        projectId: activity.project.projectId,
      },
    }}},[_vm._v(_vm._s(activity.project.title)+" ")])],1):_vm._e(),(activity.company)?_c('p',[(activity.isRemove && !(activity.note || activity.recallDate))?_c('span',[_vm._v("No longer tracking company")]):_vm._e(),(!activity.isRemove && !(activity.note || activity.recallDate))?_c('span',[_vm._v("Started tracking company")]):_vm._e(),_c('router-link',{attrs:{"to":{
      name: 'CompanyDetail',
      params: {
        companyId: activity.company.companyID,
      },
    }}},[_vm._v(_vm._s(activity.company.name))])],1):_vm._e()])])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }