
























import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import TrackingNotesCpt from "@/modules/tracking/components/TrackingNotesCpt.vue";
import { ProjectTagListCpt } from "@/modules/tags";

@Component({
  components: {
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    ProjectTagListCpt: () => Promise.resolve(ProjectTagListCpt),
  },
})
export default class TrackingNotesDialogCpt extends AppVue {
  @Prop({ default: 0 }) projectId: number;
  @Prop({ default: "" }) projectTitle: string;
  showNotesDialog: boolean = false;

  showNotes() {
    this.showNotesDialog = true;
  }

  handleNotesClose() {
    this.showNotesDialog = false;
  }

  trackingUpdated() {
    this.$emit("trackingUpdated");
  }
}
