






















import AppVue from "@/AppVue.vue";
import { ProjectTrackersModel } from "@/core/models";
import { WatcherService } from "@/core/services";
import _ from "lodash";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class TrackingNotesCpt extends AppVue {
  @Prop({ default: 0 }) projectId: number;

  @Prop({ default: [] }) projectTrackers: Array<ProjectTrackersModel>;
  @Prop({ default: [] }) availableUsers: Array<{
    userId: number;
    name: string;
    lastname: string;
    fullname: string;
  }>;
  innerCount: number = 0;
  isLoading: boolean = false;
  get getUsersTracking() {
    var project = this.projectTrackers.filter((x) => x.projectId === this.projectId);
    return project.length ? project[0].usersTracking : [];
  }
  get getUsersTrackingCount() {
    var project = this.projectTrackers.filter((x) => x.projectId === this.projectId);
    const currentNumber = project.length ? project[0].usersTracking.length : 0;
    if (currentNumber != this.innerCount) {
      // if the value is new, please force removing the loading in case it is in there
      this.isLoading = false;
    }
    this.innerCount = currentNumber;
    return this.innerCount;
  }
  async created() {}

  trackedByUser(userId: number) {
    var users = this.getUsersTracking;
    var user = users.filter((x: any) => x.userId === userId);
    return user.length > 0;
  }

  async updateProjectTrackers(userId: number) {
    this.isLoading = true;

    if (this.trackedByUser(userId)) {
      // Remove tracking
      const res = await WatcherService.unwatchProjectForUser(this.projectId, userId);
      if (res) {
        this.$emit(
          "removeUser",
          this.availableUsers.find((x) => x.userId == userId),
        );
      }
    } else {
      // Add tracking
      const res = await WatcherService.watchProjectForUser(this.projectId, userId);
      if (res) {
        this.$emit(
          "addUser",
          this.availableUsers.find((x) => x.userId == userId),
        );
      }
    }
    this.$emit("updateTrackers");
  }
}
