



















































import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatcherService } from "@/core/services";
import { TrackingTeamActivityModel } from "@/core/models";

@Component({})
export default class TeamActivityCpt extends AppVue {
  loading = false;
  model: TrackingTeamActivityModel[] = [];

  mounted() {}

  async created() {
    this.initializeModel();
  }

  private async initializeModel() {
    this.loading = true;
    try {
      this.model = await WatcherService.activityList();
    } finally {
      this.loading = false;
    }
  }
}
