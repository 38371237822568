

















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { WatcherService, TrackingPagedCollectionFilter } from "@/core/services";
import { SectorIconCpt, BlankSlateCpt, WatchedButton } from "@/core/components";
import { PagedCollection, PagedCollectionFilter, UserWatch, UserWatchesResult } from "@/core/models";
import TrackingNotesCpt from "./TrackingNotesCpt.vue";
import { TrackingQuerystringFactory } from "@/modules/tracking/factories/trackingQueryStringFactory";
import moment from "moment";
import {
  ExportFormatModel,
  ExportService,
  TrackingExportConfigurationModel,
} from "@/modules/export/services/export.service";
import ExportFilterCpt from "./ExportFilterCpt.vue";
import { CreateCompanyExportCpt } from "@/modules/export/components";
import { CompanyTagListCpt } from "@/modules/tags";

@Component({
  components: {
    SectorIconCpt,
    BlankSlateCpt,
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    ExportFilterCpt: () => Promise.resolve(ExportFilterCpt),
    WatchedButton: () => Promise.resolve(WatchedButton),
    CreateCompanyExportCpt: () => Promise.resolve(CreateCompanyExportCpt),
    CompanyTagListCpt: () => Promise.resolve(CompanyTagListCpt),
  },
})
export default class WatchedCompanyListCpt extends AppVue {
  @Prop({ default: false }) viewAll: boolean;
  @Prop({ default: false }) compact: boolean;
  loading = false;
  results: PagedCollection<UserWatch> = new PagedCollection<UserWatch>();
  model: UserWatchesResult = new UserWatchesResult();
  availableUsers = [{ userId: -99, name: "", lastname: "" }];
  selectedUser: any = {};
  selectedCompanyId: number = 0;
  selectedCompany: UserWatch = new UserWatch();
  selectedCompanyName: string = "";
  selectedTags: number[] = [];
  filterUntracked = false;
  showExportDialog = false;
  filter: TrackingPagedCollectionFilter = {
    page: 1,
    pageSize: 10,
    filterUntracked: false,
    selectedTags: [],
    selectedUser: this.currentUserId,
  };
  get itemsToShow() {
    if (!this.results.items) {
      return [];
    }
    if (!this.viewAll) {
      return this.results.items!.slice(0, 5);
    }
    return this.results.items;
  }
  get exportFilters() {
    return {
      tags: this.selectedTags,
      userId: this.selectedUser.userId || 0,
      untrackedOnly: this.filterUntracked,
      outputSettings: new ExportFormatModel(),
    } as TrackingExportConfigurationModel;
  }
  changePage() {
    this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }
  nextPage() {
    this.filter.page++;
    this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }
  prevPage() {
    if (this.filter.page > 1) {
      this.filter.page--;
      this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
    }
  }

  isUpdated(item: UserWatch) {
    return moment().diff(item.company.dateUpdated, "days") < 7;
  }

  exportHandler(a: any, b: number[], c: boolean) {
    this.selectedUser = a;
    this.selectedTags = b;
    this.filterUntracked = c;
    this.showExportDialog = true;
  }

  get showNotesDialog() {
    return this.selectedCompanyId > 0;
  }

  async deleteHandler() {
    await WatcherService.unwatchAllFilteredCompanies(this.selectedUser.userId, this.selectedTags);
    await this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  async removeWatch(item: UserWatch) {
    WatcherService.unwatchCompany(item.companyID);
    await this.filterHandler(this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  async addNote(item: UserWatch) {
    this.selectedCompanyId = item.companyID;
    this.selectedCompany = item;
    this.selectedCompanyName = item.company.name;
  }
  handleNotesClose() {
    this.selectedCompanyId = 0;
  }
  get counter() {
    if (this.results && this.results.items) {
      return this.results.items.length;
    }
    return 0;
  }

  async applyHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.filter.page = 1;
    await this.filterHandler(selectedUser, selectedTags, filterUntracked);
  }
  async updateHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.filter.page = 1;
    await this.filterHandler(selectedUser, selectedTags, filterUntracked);
  }
  async selectUser(user: any) {
    this.filter.page = 1;

    await this.filterHandler(user, this.selectedTags, this.filterUntracked);
  }
  async filterHandler(selectedUser: any, selectedTags: number[], filterUntracked: boolean) {
    this.loading = true;
    this.selectedUser = selectedUser;
    this.filterUntracked = filterUntracked;
    this.selectedTags = selectedTags;
    try {
      this.results = await ExportService.filterTrackedCompanies(
        selectedUser.userId,
        selectedTags,
        this.filter.page,
        filterUntracked,
      );
      if (this.viewAll) {
        this.$router
          .replace({
            path: this.$route.params[0],
            query: TrackingQuerystringFactory.default(
              this.filter,
              this.selectedTags,
              this.filterUntracked,
              this.selectedUser,
            ),
          })
          // the catch is required because vue doesn't allow reloading the same url. By design. Yes, I think it's wrong, and I am not the only one.
          .catch((err) => {});
      }
      this.model.watches = this.results.items;
      this.model.total = this.results.recordCount;
    } catch {}
    this.loading = false;
  }

  convertQuerystring() {
    this.filter = TrackingQuerystringFactory.convertFromQuerystring(this.$route.query, this.filter);
  }

  async mounted() {
    this.initializeModel();
  }

  async created() {
    this.convertQuerystring();
  }

  private async initializeModel() {
    this.loading = true;

    try {
      await this.filterHandler(
        { userId: this.filter.selectedUser },
        this.filter.selectedTags,
        this.filter.filterUntracked,
      );
    } finally {
      this.loading = false;
    }
  }
}
