





























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { TagModel, TagService, TrackingPagedCollectionFilter, WatcherService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class ExportFilterCpt extends AppVue {
  @Prop({ default: () => {} }) user: any;
  @Prop({ default: () => {} }) filter: TrackingPagedCollectionFilter;
  @Prop({ default: 0 }) recordCount: number;
  selectedTags: number[] = [];
  allTags: TagModel[] = [];
  selectedUser = { userId: -99, name: "", lastname: "" };
  availableUsers = [{ userId: -99, name: "", lastname: "" }];
  loading = false;
  filterUntracked = false;

  @Watch("user")
  changeUser() {
    if (this.user !== this.selectedUser) {
      this.selectedUser = this.user;
    }
    if (this.selectedUser.userId === 0) {
      this.filterUntracked = false;
    }
  }

  deleteHander() {
    this.$emit("deleteHandler");
  }

  tagChangeHandler() {
    this.$emit("update", this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  userUpdated(user: any) {
    if (user.userId === 0) {
      this.filterUntracked = false;
    }
  }
  async created() {}

  async mounted() {
    await this.initializeModel();
    if (this.filter) {
      this.selectedTags = this.filter.selectedTags;
      this.selectedUser = { userId: this.filter.selectedUser, name: "", lastname: "" };
      this.filterUntracked = this.filter.filterUntracked;
    }
  }

  exportHandler() {
    this.$emit("export", this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  filterHandler() {
    this.$emit("apply", this.selectedUser, this.selectedTags, this.filterUntracked);
  }

  async initializeModel() {
    this.loading = true;
    try {
      this.availableUsers = await WatcherService.getAllVisibleUsers();
      this.availableUsers.unshift({ userId: 0, name: "All users", lastname: "" });
      this.selectedUser = this.availableUsers.find((x) => x.userId === this.currentUserId) || this.selectedUser;
      this.allTags = await TagService.getAllVisibleTags();
    } finally {
      this.loading = false;
    }
  }
}
