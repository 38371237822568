import {
    TrackingView,
    TrackingProjectsView,
    TrackingCompaniesView,
} from "@/modules/tracking";

export const trackingRoutes = [
    {
        path: "/tracking",
        name: "tracking",
        component: TrackingView,
    },
    {
        path: "/tracking/projects",
        name: "tracking-projects",
        component: TrackingProjectsView,
    },
    {
        path: "/tracking/companies",
        name: "tracking-companies",
        component: TrackingCompaniesView,
    },
];
