import { TrackingPagedCollectionFilter } from "@/core/services";

export class TrackingQuerystringFactory {
    static default(
        filter: TrackingPagedCollectionFilter,
        selectedTags: number[],
        filterUntracked: boolean,
        selectedUser: any) {
        return {
            page: filter.page.toString(),
            filterUntracked: String(filterUntracked),
            selectedTags: selectedTags.toString(),
            selectedUser: selectedUser.userId.toString(),
        };
    }
    static convertFromQuerystring(
        query: any,
        filter: TrackingPagedCollectionFilter) {
        if (query.page || query.filterUntracked || query.selectedTags || query.selectedUser) {
            filter.page = parseInt(query.page as string, 10);
            filter.filterUntracked = (query.filterUntracked as string) === "true";
            filter.selectedTags = (query.selectedTags as string)
                .split(",")
                .map((i) => parseInt(i, 10))
                .filter(Number);
            filter.selectedUser = Number(query.selectedUser);
        }
        return filter;
    }
}
